import React, { FunctionComponent } from 'react';
import { Header as LMDSHeader, LogoLibertyMutualHoriz as LibertyLogo } from '@lmig/lmds-react';

import './assets/Header.scss';

const Header: FunctionComponent = () => (
  <LMDSHeader id="lmds-header">
    <LibertyLogo className="header-logo" />
  </LMDSHeader>
);

export default Header;
