import React, { FunctionComponent } from 'react';
import { Footer as LMDSFooter, Disclaimer } from '@lmig/lmds-react';

import './assets/Footer.scss';

const Footer: FunctionComponent = () => (
  <LMDSFooter>
    <hr className="horizontal-rule" />
    <Disclaimer>
      <p>© 2021 Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116</p>
    </Disclaimer>
  </LMDSFooter>
);

export default Footer;
