import React, { FunctionComponent, useState } from 'react';
import axios from 'axios';
import {
  AlphanumericInput, FieldGroup, GridRow,
  GridCol, Button, Form,
} from '@lmig/lmds-react';
import CallTransfer from './LIComponents/CallTransfer';
import CoverageList from './LIComponents/CoverageList';
import StateSelect from './LIComponents/StateSelect';

const LeadIntakeForm: FunctionComponent = () => {

  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  
  const [disable, setDisable] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState<LeadIntakeFormErrors>({
    repName: false,
    nnumber: false,
    nnumberFormat: false,
    nnumberNotValid: false,
    agencyEmail: false,
    agencyPhoneNumber: false,
    insuredName: false,
    firstName: false,
    lastName: false,
    customerPhoneNumber: false,
    streetAddress: false,
    city: false,
    state: false,
    zipCode: false,
    customerEmailAddress: false,
    descBusinessOperation: false,
    coverages: false,
    blscRepName: false,
    callTransfer: false,
  });

  const [coverages, setCoverages] = useState<Array<string>>([])

  const initLeadIntakeData = {
    repName: '',
    nnumber: '',
    agencyEmail: '',
    agencyPhoneNumber: '',
    insuredName: '',
    firstName: '',
    lastName: '',
    customerPhoneNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    customerEmailAddress: '',
    descBusinessOperation: '',
    coverages: [],
    blscRepName: '',
    callTransfer: ''
  }
  const [leadIntakeData, setLeadIntakeData] = useState<LeadIntakeFormProps>(initLeadIntakeData)

  const resetForm = () => {
    const newLeadIntakeData = {...leadIntakeData};
    let property: keyof LeadIntakeFormProps;
    for (property in newLeadIntakeData) {
      if (property === "coverages") {
        newLeadIntakeData[property] = [];
      } else {
        newLeadIntakeData[property] = '';
      }
    }
    setLeadIntakeData(newLeadIntakeData);
  }

  const validateForm = () => {

    let errors = {
        repName: false,
        nnumber: false,
        nnumberFormat: false,
        nnumberNotValid: false,
        agencyEmail: false,
        agencyPhoneNumber: false,
        insuredName: false,
        firstName: false,
        lastName: false,
        customerPhoneNumber: false,
        streetAddress: false,
        city: false,
        state: false,
        zipCode: false,
        customerEmailAddress: false,
        descBusinessOperation: false,
        coverages: false,
        blscRepName: false,
        callTransfer: false,
    }
    if (leadIntakeData.repName.length === 0) {
         errors.repName = true
    }

    // Validate N#
		const repNNum = leadIntakeData.nnumber;
    const firstChar = leadIntakeData.nnumber.substring(0, 1);
		const last7 = leadIntakeData.nnumber.substring(1, 8);
		if (repNNum.length > 0) {
      if ((firstChar !== "N" && firstChar !== "n") || (repNNum.length < 8 || repNNum.length > 8) || isNaN(+last7)) {
        errors.nnumberFormat = true;
			}
		} else {
			errors.nnumber = true;
		}

    // Validate Email
    let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (leadIntakeData.agencyEmail.length === 0 || emailReg.test(leadIntakeData.agencyEmail) === false) {
        errors.agencyEmail = true
    }
    if (leadIntakeData.customerEmailAddress.length === 0 || emailReg.test(leadIntakeData.customerEmailAddress) === false) {
      errors.customerEmailAddress = true
    }

    // Validate Phone
    if (leadIntakeData.agencyPhoneNumber.length === 0 || (leadIntakeData.agencyPhoneNumber.length < 10 || leadIntakeData.agencyPhoneNumber.length > 10)) {
        errors.agencyPhoneNumber = true
    }
    if (leadIntakeData.customerPhoneNumber.length === 0 || (leadIntakeData.customerPhoneNumber.length < 10 || leadIntakeData.customerPhoneNumber.length > 10)) {
      errors.customerPhoneNumber = true
    }


    if (leadIntakeData.insuredName.length === 0) {
        errors.insuredName = true
    }
    if (leadIntakeData.firstName.length === 0) {
        errors.firstName = true
    }
    if (leadIntakeData.lastName.length === 0) {
        errors.lastName = true
    }
    if (leadIntakeData.streetAddress.length === 0) {
        errors.streetAddress = true
    }
    if (leadIntakeData.city.length === 0) {
        errors.city = true
    }
    if (leadIntakeData.state.length === 0) {
        errors.state = true
    }

    // Validate Zip
    let zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (leadIntakeData.zipCode.length === 0 || zipReg.test(leadIntakeData.zipCode) === false) {
        errors.zipCode = true
    }

    if (leadIntakeData.descBusinessOperation.length === 0) {
        errors.descBusinessOperation = true
    }
    if (leadIntakeData.coverages.length === 0) {
        errors.coverages = true
    }
    if (leadIntakeData.callTransfer.length === 0) {
        errors.callTransfer = true
    }

    //console.log(errors)
    let property: keyof LeadIntakeFormErrors;
    for (property in errors) {
      if (errors[property] === true) {
        setErrorMessage(errors)
        return true;
      }
    }

    const resetErr: LeadIntakeFormErrors = {...errorMessage};
    for (property in resetErr) {
      resetErr[property] = false;
    }
    setErrorMessage(resetErr);

  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const err = validateForm();

    if (!err) {
      //console.log("** Submitting");
      var formDetails = {
        'formData': JSON.stringify({ leadIntakeData }),
        'formName': 'leadIntake',
      };

      try {
        axios.post('/api/process', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ formDetails })
        })
          .then((response: any) => response.data)
          .catch((err)=>{
            //console.log(err)
            setDisable(false);
            alert("An Error occurred during processing of form data.");
          })
          .then((responseData) => {
            //console.log(responseData);
            if (responseData['statusValNNum'] !== "Success") {
              // Invalid N# was entered
              let property: keyof LeadIntakeFormErrors;
              const resetErr: LeadIntakeFormErrors = { ...errorMessage };
              for (property in resetErr) {
                resetErr[property] = false;
                if (property === "nnumberNotValid") {
                  resetErr[property] = true;
                }
              }
              setErrorMessage(resetErr);
              setDisable(false);
              window.scrollTo(0, 0);
            } else {
              if (responseData['status'] !== "Success") {
                // Failed to process the form data.
                setDisable(false);
                alert("An Error occurred during processing of form data.");
              } else {
                // Successful form processing. Show success text
                resetForm();
                setFormSuccess(true);
                setDisable(false);
                window.scrollTo(0, 0);
              }
            }
          })
      } catch (err) {
        console.log(err);
        setDisable(false);
        alert("An Error occurred during processing of form data.");
      }
    } else {
      setDisable(false);
      window.scrollTo(0, 0);
    }
  };

  const change = (e: any) => {
    setLeadIntakeData({
      ...leadIntakeData,
      [e.target.name]: e.target.value,
    });
  };

  const handleArrChange = (e: any): void => {
    
    const checkedCoverages: Array<string> = [...coverages];

    if (e.target.checked) {
      checkedCoverages.push(e.target.value);
    } else {
      const index = checkedCoverages.indexOf(e.target.value);

      checkedCoverages.splice(index, 1);
    }
    checkedCoverages.sort();
    setCoverages(checkedCoverages);
    setLeadIntakeData({...leadIntakeData, coverages: checkedCoverages});
  };

  return (

    <Form onSubmit={handleSubmit}>
      <div style={{display: formSuccess ? 'inline' : 'none'}}>
        <h2>Small Commercial Lead Intake Form</h2> 
        Thank you for your submission. 
        <br/> 
        <br/> 
        <b>If you transferred your customer to the BL Sales Center or are working with a Rep 
          already</b>, here's what you can expect:
        <br/><br/>
        <p> 1. We'll work with your client to ensure we have a product that's tailored to their 
          business needs.</p>
        <p> 2. We'll let you know if we're able to provide your customer with a policy or if 
          their business was out of our appetite. </p>
        <br/><br/>
        <b>If you did not connect your customer with the BLSC, here's what you can expect:</b>
        <br/><br/>
        <p> 1. Our team will let you know we received your submission.</p>
        <p> 2. We'll contact your referral within 24 hours.</p>
        <p> 3. We'll let you know if we're able to provide your referral with a policy or if 
          their business was out of our appetite. </p>
      </div>

      <div style={{display: formSuccess ? 'none' : 'inline'}}>
        <p> * All fields with an asterisk are required.</p>
        <h2>Small Business Lead Intake Form</h2>
        <p><strong>Increase your chances of a sale by warm transferring your customer to the Business Lines Sales Center at 844-219-6911! Don&apos;t forget to fill out this form, first!</strong></p>
        {/* Agency Information section */}
        <FieldGroup id="agencyInfo" labelVisual="Agency Information">
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Liberty Rep Full Name" name="repName" value={leadIntakeData.repName} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.repName ? 'inline' : 'none' }}>
                          Liberty Rep Full Name required</span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Liberty Rep N Number (N#######)" name="nnumber" value={leadIntakeData.nnumber} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.nnumber ? 'inline' : 'none' }}>
                          USCM Rep N Number required</span>
              <span style={{ color: 'red', display: errorMessage.nnumberFormat ? 'inline' : 'none' }}>
                          Rep N number must be entered as Nxxxxxxx or nxxxxxxx.</span>
              <span style={{ color: 'red', display: errorMessage.nnumberNotValid ? 'inline' : 'none' }}>
                          Rep N number not valid</span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Liberty Rep Liberty Mutual Email" type="email" name="agencyEmail" value={leadIntakeData.agencyEmail} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.agencyEmail ? 'inline' : 'none' }}>Valid Email Address required</span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Cell Phone (Numbers only)" type="number" name="agencyPhoneNumber" value={leadIntakeData.agencyPhoneNumber} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.agencyPhoneNumber ? 'inline' : 'none' }}>
                          Valid Cell Phone Number (Numbers Only) required</span>
            </GridCol>
          </GridRow>
        </FieldGroup>

        {/* Prospective Customer section */}
        <FieldGroup id="prospectiveCustomer" labelVisual="Prospective Customer">
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Named Insured" type="text" name="insuredName" value={leadIntakeData.insuredName} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.insuredName ? 'inline' : 'none' }}>Business Name required</span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* First name" type="text" name="firstName" value={leadIntakeData.firstName} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.firstName ? 'inline' : 'none' }}>First Name required</span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Last Name" type="text" name="lastName" value={leadIntakeData.lastName} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.lastName ? 'inline' : 'none' }}>Last Name required</span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Phone (Numbers only)" type="number" name="customerPhoneNumber" value={leadIntakeData.customerPhoneNumber} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.customerPhoneNumber ? 'inline' : 'none' }}>Valid Phone Number (Numbers Only)required</span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Street Address" type="text" name="streetAddress" value={leadIntakeData.streetAddress} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.streetAddress ? 'inline' : 'none' }}>Street Address required</span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* City" type="text" name="city" value={leadIntakeData.city} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.city ? 'inline' : 'none' }}>City required</span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={4}>
              <StateSelect value={leadIntakeData.state} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.state ? 'inline' : 'none' }}>State required</span>
            </GridCol>
            <GridCol base={2} />
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Zip Code" type="number" name="zipCode" value={leadIntakeData.zipCode} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.zipCode ? 'inline' : 'none' }}>Zip Code required</span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput labelVisual="* Email Address" type="email" name="customerEmailAddress" value={leadIntakeData.customerEmailAddress} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.customerEmailAddress ? 'inline' : 'none' }}>Valid Email Address required</span>
            </GridCol>
          </GridRow>
        </FieldGroup>

        {/* Business Operation */}
        <FieldGroup id="busOps" labelVisual="Business Operation">
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={12}>
              <div>
                <p>* Description of business operation</p>
                <br />
                <textarea maxLength={255} wrap="soft" rows={3} cols={72.5} name="descBusinessOperation" value={leadIntakeData.descBusinessOperation} onChange={change} />
              </div>
              <span style={{ color: 'red', display: errorMessage.descBusinessOperation ? 'inline' : 'none'  }}>Description of business operation required</span>
            </GridCol>
          </GridRow>
          
          <span className="required-marker" />
          <p>* I&apos;m interested in the following coverage(s)&#58;</p>
          {/* coverages */}
          <GridRow>
            <CoverageList data-testid="coverages-test" onChange={handleArrChange} />
            <span id="lmgbi_productAlert" style={{ color: 'red', display: errorMessage.coverages ? 'inline' : 'none' }}> I'm interested in the following coverage(s) required </span>
          </GridRow>
{/*
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={12}>
              <div>
                <p>Liberty BLSC Rep you are working with? (Optional)</p>
                <br />
                <textarea maxLength={400} wrap="soft" rows={3} cols={72.5} name="blscRepName" value={leadIntakeData.blscRepName} onChange={change} />
              </div>
            </GridCol>
          </GridRow>
*/}
        </FieldGroup>
        {/* Submit Form */}
        <FieldGroup id="submitForm" labelVisual="Submit Form">
          <CallTransfer value={leadIntakeData.callTransfer} onChange={change} />
          <span style={{ color: 'red', display: errorMessage.callTransfer ? 'inline' : 'none'  }}> Form Type required </span>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={2}>
              <Button type="submit" dynamicWidth variant="primary" disabled={disable} onClick={() => setDisable(true)}>Submit</Button>
            </GridCol>
          </GridRow>
        </FieldGroup>
      </div>
    </Form>
  );
};

export default LeadIntakeForm;
