const agencyFormData = {
    agencyStructure: [
      "Corporation",
      "Partnerships",
      "Sole Proprieter",
      "LLC",
      "LLP",
      "Other"
    ],
    agencyYears: [
      "1 Year",
      "1-3 Years",
      "Greater than 3 Years"
    ],
    agencyWrites: [
      "Small",
      "Mid-Size",
      "Large (1000+ employees)"
    ],
    agencyPrem: [
      "Less than $100,000",
      "$100,000 to $1m",
      "$1m to $5m",
      "Greater than $5m"
    ],
    commCSR: [
      "0",
      "1-5",
      "6-10",
      "11-30",
      "More than 30"
    ],
    commProd: [
      "1-5",
      "6-10",
      "11-30",
      "Greater than 30"
    ],
    commAcc: [
      "1-5",
      "6-10",
      "11-50",
      "Greater than 50"
    ]
  }
  
  export default agencyFormData;