import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

// Presentational Components
import LeadIntakePage from '../LeadIntake/LeadIntakePage';
import HomePage from '../HomePage/HomePage';
import LeadGeneralPage from '../LeadIntakeGeneral/LeadGeneralPage';

import AgencyApptReqPage from '../AgencyApptReq/AgencyApptReqPage';
import AARPostSub from '../AARPostSub/AARPostSub';

const Routes: FunctionComponent = () => (
  <Switch>

    <Route exact path="/">
      <HomePage />
    </Route>
    
    <Route exact path="/leadIntake">
      <LeadIntakePage />
    </Route>

    <Route exact path="/leadIntakeGeneral">
      <LeadGeneralPage />
      </Route>
    <Route exact path="/agencyAppointmentRequest">
      <AgencyApptReqPage />
    </Route>

    <Route exact path="/aarpostSubmission">
      <AARPostSub />
    </Route>

  </Switch>
);

export default Routes;
