import { GridRow, GridCol } from '@lmig/lmds-react';
import React, { FunctionComponent } from 'react';
import AgencyApptReqForm from './AgencyApptReqForm';

const AgencyApptReqPage: FunctionComponent = () => (
  <div>
    <GridRow gutters>
      <GridCol base={12}>
        <AgencyApptReqForm />
      </GridCol>
    </GridRow>
  </div>
)

export default AgencyApptReqPage;