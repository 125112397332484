import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { BodyText, Heading } from '@lmig/lmds-react';

import './assets/HomePage.scss';

const HomePage: FunctionComponent = () => {

  return (
    <span className="homePage">
      <Heading tag="h1" type="h2-light">
        Welcome to the HomePage
      </Heading>
      <BodyText>
        <h1>This is the Home Page</h1>
        <ul className="navbar">
          <li><Link to="/">Home Page</Link></li>
          <li><Link to="/leadIntake">Lead Intake</Link></li>
          <li><Link to="/leadIntakeGeneral">General Lead Intake</Link></li>
          <li><Link to="/agencyAppointmentRequest">Agency Appointment Request</Link></li>
        </ul>
      </BodyText>
    </span>
  );
};

export default HomePage;
