import React, { FunctionComponent } from 'react';
import { CheckboxGroup, Checkbox } from '@lmig/lmds-react';

// eslint-disable-next-line react/prop-types
const CoverageList: FunctionComponent<ValueChangeProps> = ({ onChange }) => (
  <CheckboxGroup data-testid="coverages-test" className="testing" name="coverages" onChange={onChange}>
    <Checkbox labelVisual="Property" value="property" />
    <Checkbox labelVisual="General Liability" value="genLiability" />
    <Checkbox labelVisual="Inland Marine" value="inlandMarine" />
    <Checkbox labelVisual="Surety Bond" value="suretyBond" />
    <Checkbox labelVisual="Auto" value="auto" />
    <Checkbox labelVisual="Workers Compensation" value="workComp" />
    <Checkbox labelVisual="Umbrella" value="umbrella" />
  </CheckboxGroup>
);

export default CoverageList;
