import { GridRow, GridCol } from '@lmig/lmds-react';
import React, { FunctionComponent } from 'react';

import LeadIntakeForm from './LeadIntakeForm';
import AppetiteAndProgramResourcesCard from '../AppetiteAndProgramResourcesCard';
import './../Global.scss';

const LeadIntakePage: FunctionComponent  = () => (
  <div>
    <GridRow gutters>
      <GridCol base={8}>
        <LeadIntakeForm />
      </GridCol>
      <GridCol base={4} className='lead-intake-vertical-align'>
        <AppetiteAndProgramResourcesCard />
      </GridCol>
    </GridRow>
  </div>
);

export default LeadIntakePage;
