import React from 'react';
import { Card } from '@lmig/lmds-react';
import AppetiteGuideLink from './AppetiteGuideLink';
import ProgramResourcesLink from './ProgramResourcesLink';

const AppetiteAndProgramResourcesCard = () => (
  <div>
    <Card>
      <h3>Appetite and Program Resources</h3>
      Have questions? Click <AppetiteGuideLink /> to check the more small business appetite guide or&nbsp; 
      <ProgramResourcesLink /> to learn more about the program.
    </Card>
  </div>
);

export default AppetiteAndProgramResourcesCard;