import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Content } from '@lmig/lmds-react';
import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Routes from './components/Routes/Routes';

const App: FunctionComponent = () => {
  return (
    <Router>
      <Header />
      <Content>
        <Routes />
      </Content>
      <Footer />
    </Router>
  );
}

export default App;
