import { FunctionComponent, useEffect, useState } from "react";
import {
  FieldGroup,
  GridRow,
  GridCol,
  Button,
  Form,
  AlphanumericInput,
} from "@lmig/lmds-react";
import axios from "axios";
import StateSelect from "../LeadIntake/LIComponents/StateSelect";
import CoverageList from "../LeadIntake/LIComponents/CoverageList";

const LeadGeneralForm: FunctionComponent = () => {
  const [coverages, setCoverages] = useState<Array<string>>([]);
  
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [leadGenData, setLeadGenData] = useState<LeadGenFormProps>({
    aCode: "",
    prodCell: "",
    prodName: "",
    prodEmail: "",
    insName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    state: "",
    busOps: "",
    coverages: [],
  });

  const [errorMessage, setErrorMessage] = useState<LeadGenFormErrors>({
    aCode: false,
    prodName: false,
    prodEmail: false,
    insName: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    state: false,
    busOps: false,
    coverages: false,
  });

  const resetForm = () => {
    setLeadGenData({
      aCode: "",
      prodCell: "",
      prodName: "",
      prodEmail: "",
      insName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      state: "",
      busOps: "",
      coverages: [],
    });
  };

  const validateForm = () => {
    let errors = {
      aCode: false,
      prodName: false,
      prodEmail: false,
      insName: false,
      firstName: false,
      lastName: false,
      phoneNumber: false,
      email: false,
      state: false,
      busOps: false,
      coverages: false,
    };

    if (leadGenData.aCode.length === 0) {
      errors.aCode = true;
    }

    if (leadGenData.prodName.length === 0) {
      errors.prodName = true;
    }

    // Validate Email
    let emailReg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      leadGenData.prodEmail.length === 0 ||
      emailReg.test(leadGenData.prodEmail) === false
    ) {
      errors.prodEmail = true;
    }
    if (
      leadGenData.email.length === 0 ||
      emailReg.test(leadGenData.email) === false
    ) {
      errors.email = true;
    }

    // Validate Phone
    if (
      leadGenData.phoneNumber.length === 0 ||
      leadGenData.phoneNumber.length < 14 ||
      leadGenData.phoneNumber.length > 14
    ) {
      errors.phoneNumber = true;
    }

    if (leadGenData.insName.length === 0) {
      errors.insName = true;
    }
    if (leadGenData.firstName.length === 0) {
      errors.firstName = true;
    }
    if (leadGenData.lastName.length === 0) {
      errors.lastName = true;
    }

    if (leadGenData.state.length === 0) {
      errors.state = true;
    }

    if (leadGenData.busOps.length === 0) {
      errors.busOps = true;
    }
    if (leadGenData.coverages.length === 0) {
      errors.coverages = true;
    }

    setErrorMessage(errors);

    for (const [k, v] of Object.entries(errors)) {
      if (v === true) {
        return true;
      }
    }

    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const err = validateForm();

    if (!err) {
      var formDetails = {
        formData: JSON.stringify({ leadGenData }),
        formName: "leadGenIntake",
      };

      try {
        axios
          .post("/api/process", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ formDetails }),
          })
          .then((response: any) => response.data)
          .catch((err) => {
            //console.log(err) 
            alert("An Error occurred during processing of form data.");
          })
          .then((responseData) => {
            //console.log(responseData);

            if (responseData["status"] !== "Success") {
              // Failed to process the form data.
            
              alert("An Error occurred during processing of form data.");
            } else {
              // Successful form processing. Show success text
              resetForm();
              setFormSuccess(true);
       
              window.scrollTo(0, 0);
            }
          });
      } catch (err) {
        console.log(err);
        alert("An Error occurred during processing of form data.");
      }
    } else {
      
      window.scrollTo(0, 0);
    }
  };

  const change = (e: any) => {
    setLeadGenData({
      ...leadGenData,
      [e.target.name]: e.target.value,
    });
  };

  const handleArrChange = (e: any): void => {
    
    const checkedCoverages: Array<string> = [...coverages];

    if (e.target.checked) {
      checkedCoverages.push(e.target.value);
    } else {
      const index = checkedCoverages.indexOf(e.target.value);

      checkedCoverages.splice(index, 1);
    }
    checkedCoverages.sort();
    setCoverages(checkedCoverages);
    setLeadGenData({...leadGenData, coverages: checkedCoverages});
  };

  return (
    <Form>
      <h1>Lead Intake General</h1>

      <div style={{ display: formSuccess ? "inline" : "none" }}>
        <h2>Small Commercial Lead General Form</h2>
        Thank you for your submission.
        <br />
        <br />
        <b>
          If you transferred your customer to the BL Sales Center or are working
          with a Rep already
        </b>
        , here's what you can expect:
        <br />
        <br />
        <p>
          {" "}
          1. We'll work with your client to ensure we have a product that's
          tailored to their business needs.
        </p>
        <p>
          {" "}
          2. We'll let you know if we're able to provide your customer with a
          policy or if their business was out of our appetite.{" "}
        </p>
        <br />
        <br />
        <b>
          If you did not connect your customer with the BLSC, here's what you
          can expect:
        </b>
        <br />
        <br />
        <p> 1. Our team will let you know we received your submission.</p>
        <p> 2. We'll contact your referral within 24 hours.</p>
        <p>
          {" "}
          3. We'll let you know if we're able to provide your referral with a
          policy or if their business was out of our appetite.{" "}
        </p>
      </div>

      <div style={{ display: formSuccess ? "none" : "inline" }}>
        {/* Agency Information section */}
        <FieldGroup
          disableMargins="top"
          id="agencyInfo"
          labelTag="h1"
          labelVisual="Agency Information"
        >
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="00N3n000003nLZA"
                labelVisual="* Agency Code"
                name="aCode"
                value={leadGenData.aCode}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.aCode ? "inline" : "none",
                }}
              >
                Agency Code required
              </span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="00N3n000003vMG8"
                mask="phone"
                labelVisual="Producer's Mobile/Cell"
                name="prodCell"
                value={leadGenData.prodCell}
                onChange={change}
              />
            </GridCol>
          </GridRow>
          <p className="gray">
            <i>
              *Acknowledgement of lead submission will be sent via text to the
              above provided number.
            </i>
          </p>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="00N3n000003vMG7"
                filter={/^[A-Za-z- ]*$/}
                labelVisual="* Producer Name"
                name="prodName"
                value={leadGenData.prodName}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.prodName ? "inline" : "none",
                }}
              >
                Producer Name required
              </span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="00N3n000003vMG6"
                labelVisual="* Producer's Email"
                name="prodEmail"
                value={leadGenData.prodEmail}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.prodEmail ? "inline" : "none",
                }}
              >
                Produer Email required
              </span>
            </GridCol>
          </GridRow>
          <p className="gray">
            <i>
              *Notification of lead outcome will be sent to above provided email
            </i>
          </p>
        </FieldGroup>

        {/* Prospective Customer section */}
        <FieldGroup
          id="prospectiveCustomer"
          labelTag="h1"
          labelVisual="Prospective Customer"
        >
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="company"
                labelVisual="* Named Insured"
                type="text"
                name="insName"
                value={leadGenData.insName}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.insName ? "inline" : "none",
                }}
              >
                Insured Name required
              </span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="first_name"
                filter={/^[A-Za-z- ]*$/}
                labelVisual="* First name"
                type="text"
                name="firstName"
                value={leadGenData.firstName}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.firstName ? "inline" : "none",
                }}
              >
                First Name required
              </span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="last_name"
                // eslint-disable-next-line no-useless-escape
                filter={/^[A-Za-z- \.]*$/}
                labelVisual="* Last Name"
                type="text"
                name="lastName"
                value={leadGenData.lastName}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.lastName ? "inline" : "none",
                }}
              >
                Last Name required
              </span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="phone"
                labelVisual="* Phone Number"
                mask="phone"
                name="phoneNumber"
                value={leadGenData.phoneNumber}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.phoneNumber ? "inline" : "none",
                }}
              >
                Phone Number required
              </span>
            </GridCol>
          </GridRow>
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput
                data-field-name="email"
                labelVisual="* Email Address"
                name="email"
                value={leadGenData.email}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.email ? "inline" : "none",
                }}
              >
                Customer Email required
              </span>
            </GridCol>
            <GridCol base={6}>
              <StateSelect value={leadGenData.state} onChange={change} />
              <span
                style={{
                  color: "red",
                  display: errorMessage.state ? "inline" : "none",
                }}
              >
                State required
              </span>
            </GridCol>
          </GridRow>
        </FieldGroup>

        {/* Business Operation section */}
        <FieldGroup id="busOps" labelTag="h1" labelVisual="Business Operation">
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={12}>
              <AlphanumericInput
                data-field-name="00N80000005X5wt"
                labelVisual="* Business Type"
                name="busOps"
                value={leadGenData.busOps}
                onChange={change}
              />
              <span
                style={{
                  color: "red",
                  display: errorMessage.busOps ? "inline" : "none",
                }}
              >
                Business Type required
              </span>
            </GridCol>
          </GridRow>

          {/* coverages */}
          <GridRow>
            <CoverageList
              data-testid="coverages-test"
              onChange={handleArrChange}
            />
            <span
              style={{
                color: "red",
                display: errorMessage.coverages ? "inline" : "none",
              }}
            >
              Coverage required
            </span>
          </GridRow>
        </FieldGroup>

        {/* Submit Form */}
        <FieldGroup id="submitForm" labelVisual="">
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={2}>
              <Button
                dynamicWidth
                variant="primary"
                
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </GridCol>
          </GridRow>
        </FieldGroup>
        <p> * All fields with an asterisk are required.</p>
      </div>
    </Form>
  );
};

export default LeadGeneralForm;
