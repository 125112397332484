import { BodyText } from '@lmig/lmds-react';
import React, { FunctionComponent } from 'react';

const AARPostSub: FunctionComponent = () => {

  return (
    <>
      <BodyText>
      <p className="gray"><strong>Thank you for your interest in Liberty Mutual Insurance.</strong></p>
      <p className="gray"><strong>We have received your Agency Appointment Request Form.</strong></p>
      <p className="gray"><strong>A Liberty Mutual Insurance Representative will review your information and contact you soon.</strong></p>
      </BodyText>
    </>
  );
};

export default AARPostSub;