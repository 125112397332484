import { GridRow, GridCol } from '@lmig/lmds-react';
import React, { FunctionComponent } from 'react';
import LeadGeneralForm from './LeadGeneralForm';
import AppetiteAndProgramResourcesCard from '../AppetiteAndProgramResourcesCard';
import './../Global.scss';

const LeadGeneralPage: FunctionComponent  = () => (
  <div>
    <GridRow gutters>
      <GridCol base={8}>
        <LeadGeneralForm />
      </GridCol>
      <GridCol base={4} className='lead-intake-vertical-align'>
        <AppetiteAndProgramResourcesCard />
      </GridCol>
    </GridRow>
  </div>
);

export default LeadGeneralPage;