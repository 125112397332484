import React, { FunctionComponent } from 'react';
import { RadioGroup, Radio } from '@lmig/lmds-react';

const CallTransfer: FunctionComponent<ValueChangeProps> = ({ value, onChange }) => (
  <>
    <strong>
      ***Please select the appropriate answer
      so we can either contact your customer or get the file to your BLSC Rep.
    </strong>
    <br />
    <span className="required-marker" />
    <p>* Form Type</p>
    <RadioGroup name="callTransfer" value={value} onChange={onChange}>
      <Radio
        id="one"
        labelVisual="Call NOT transferred to BLSC. I would like&#160;the first available Business Lines sales representative to contact my customer."
        value="leadintake"
      />
      <Radio
        id="two"
        labelVisual="Call transferred to BLSC. I will warm transfer my customer to the BLSC (844-219-6911) or am already working with the BLSC on this customer. (You MUST tell the agent that you've clicked submit so they can find this referral.)"
        value="warmintake"
      />
    </RadioGroup>
  </>
);

export default CallTransfer;
