import React, { FunctionComponent } from 'react';
import { Select, SelectOption } from '@lmig/lmds-react';



const StateSelect: FunctionComponent<ValueChangeProps> = ({ value, onChange}) => (
  <Select
    id="select--default"
    isRequired
    labelA11y="Pick your State"
    labelVisual="* Select State"
    placeholderOption="Pick your State"
    name="state"
    onChange={onChange}
    value={value}
  >
    <SelectOption value="AK">AK</SelectOption>
    <SelectOption value="AL">AL</SelectOption>
    <SelectOption value="AR">AR</SelectOption>
    <SelectOption value="AZ">AZ</SelectOption>
    <SelectOption value="CA">CA</SelectOption>
    <SelectOption value="CO">CO</SelectOption>
    <SelectOption value="CT">CT</SelectOption>
    <SelectOption value="DC">DC</SelectOption>
    <SelectOption value="DE">DE</SelectOption>
    <SelectOption value="FL">FL</SelectOption>
    <SelectOption value="GA">GA</SelectOption>
    <SelectOption value="IA">IA</SelectOption>
    <SelectOption value="ID">ID</SelectOption>
    <SelectOption value="IL">IL</SelectOption>
    <SelectOption value="IN">IN</SelectOption>
    <SelectOption value="KS">KS</SelectOption>
    <SelectOption value="KY">KY</SelectOption>
    <SelectOption value="LA">LA</SelectOption>
    <SelectOption value="MA">MA</SelectOption>
    <SelectOption value="MD">MD</SelectOption>
    <SelectOption value="ME">ME</SelectOption>
    <SelectOption value="MI">MI</SelectOption>
    <SelectOption value="MN">MN</SelectOption>
    <SelectOption value="MO">MO</SelectOption>
    <SelectOption value="MS">MS</SelectOption>
    <SelectOption value="MT">MT</SelectOption>
    <SelectOption value="NC">NC</SelectOption>
    <SelectOption value="ND">ND</SelectOption>
    <SelectOption value="NE">NE</SelectOption>
    <SelectOption value="NH">NH</SelectOption>
    <SelectOption value="NJ">NJ</SelectOption>
    <SelectOption value="NM">NM</SelectOption>
    <SelectOption value="NV">NV</SelectOption>
    <SelectOption value="NY">NY</SelectOption>
    <SelectOption value="OH">OH</SelectOption>
    <SelectOption value="OK">OK</SelectOption>
    <SelectOption value="OR">OR</SelectOption>
    <SelectOption value="PA">PA</SelectOption>
    <SelectOption value="RI">RI</SelectOption>
    <SelectOption value="SC">SC</SelectOption>
    <SelectOption value="SD">SD</SelectOption>
    <SelectOption value="TN">TN</SelectOption>
    <SelectOption value="TX">TX</SelectOption>
    <SelectOption value="UT">UT</SelectOption>
    <SelectOption value="VA">VA</SelectOption>
    <SelectOption value="VT">VT</SelectOption>
    <SelectOption value="WA">WA</SelectOption>
    <SelectOption value="WI">WI</SelectOption>
    <SelectOption value="WV">WV</SelectOption>
    <SelectOption value="WY">WY</SelectOption>
  </Select>
);

export default StateSelect;
