import React, { FunctionComponent, useState } from 'react';
import { FieldGroup, GridRow, GridCol, Button, Form, AlphanumericInput, Select, SelectOption } from '@lmig/lmds-react';
import axios from 'axios';
import StateSelect from '../LeadIntake/LIComponents/StateSelect';
import AARPostSub from '../AARPostSub/AARPostSub';
import agencyFormData from "../../data/agencyFormData";

const AgencyApptReqForm: FunctionComponent = () => {
  const [isApptReq] = useState(true)
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [disable, setDisable] = React.useState(false);
  const [apptReqData, setApptReqData] = useState<ApptReqFormProps>(
    {
      agencyName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phoneNumber: '',
      email: '',
      keyContactFirstName: '',
      keyContactLastName: '',
      keyContactTitle: '',
      agencyBusinessYrs: '',
      agencyStructure: '',
      agencySiteAddress: '',
      agencyNumOfficeLocations: '',
      officeLocations: '',
      commLineProd: '',
      commLineCSR: '',
      commLinePrem: '',
      commLineAcc: '',
      carr1: '',
      wp1: '',
      lr1: '',
      carr2: '',
      wp2: '',
      lr2: '',
      carr3: '',
      wp3: '',
      lr3: '',
      agencySpec: '',
      sizeOfAcc: '',
      addComments: ''
    }
  )

  const [errorMessage, setErrorMessage] = useState<ApptReqFormErrors>({
    agencyName: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
    phoneNumber: false,
    email: false,
    keyContactFirstName: false,
    keyContactLastName: false,
    keyContactTitle: false,
    agencyBusinessYrs: false,
    agencyStructure: false,
    agencySiteAddress: false,
    agencyNumOfficeLocations: false,
    officeLocations: false,
    commLineProd: false,
    commLineCSR: false,
    commLinePrem: false,
    commLineAcc: false,
    agencySpec: false,
    sizeOfAcc: false,
  });

  const change = (e: any) => {
    setApptReqData({
      ...apptReqData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {

    let errors = {
      agencyName: false,
      address: false,
      city: false,
      state: false,
      zipCode: false,
      phoneNumber: false,
      email: false,
      keyContactFirstName: false,
      keyContactLastName: false,
      keyContactTitle: false,
      agencyBusinessYrs: false,
      agencyStructure: false,
      agencySiteAddress: false,
      agencyNumOfficeLocations: false,
      officeLocations: false,
      commLineProd: false,
      commLineCSR: false,
      commLinePrem: false,
      commLineAcc: false,
      agencySpec: false,
      sizeOfAcc: false,
    }

    if (apptReqData.agencyName.length === 0) {
      errors.agencyName = true
    }
    if (apptReqData.address.length === 0) {
      errors.address = true
    }
    if (apptReqData.city.length === 0) {
      errors.city = true
    }
    if (apptReqData.state.length === 0) {
      errors.state = true
    }
    // Validate Zip
    let zipReg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (apptReqData.zipCode.length === 0 || zipReg.test(apptReqData.zipCode) === false) {
      errors.zipCode = true
    }
    // Validate Phone
    if (apptReqData.phoneNumber.length === 0 || (apptReqData.phoneNumber.length < 10 || apptReqData.phoneNumber.length > 14)) {
      errors.phoneNumber = true
    }
    // Validate Email
    let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (apptReqData.email.length === 0 || emailReg.test(apptReqData.email) === false) {
      errors.email = true
    }
    if (apptReqData.keyContactFirstName.length === 0) {
      errors.keyContactFirstName = true
    }
    if (apptReqData.keyContactLastName.length === 0) {
      errors.keyContactLastName = true
    }
    if (apptReqData.keyContactTitle.length === 0) {
      errors.keyContactTitle = true
    }
    if (apptReqData.agencyBusinessYrs.length === 0) {
      errors.agencyBusinessYrs = true
    }
    if (apptReqData.agencyStructure.length === 0) {
      errors.agencyStructure = true
    }
    if (apptReqData.agencySiteAddress.length === 0) {
      errors.agencySiteAddress = true
    }
    if (apptReqData.agencyNumOfficeLocations.length === 0) {
      errors.agencyNumOfficeLocations = true
    }
    if (apptReqData.officeLocations.length === 0) {
      errors.officeLocations = true
    }
    if (apptReqData.commLineProd.length === 0) {
      errors.commLineProd = true
    }
    if (apptReqData.commLineCSR.length === 0) {
      errors.commLineCSR = true
    }
    if (apptReqData.commLinePrem.length === 0) {
      errors.commLinePrem = true
    }
    if (apptReqData.commLineAcc.length === 0) {
      errors.commLineAcc = true
    }
    if (apptReqData.agencySpec.length === 0) {
      errors.agencySpec = true
    }
    if (apptReqData.sizeOfAcc.length === 0) {
      errors.sizeOfAcc = true
    }

    //console.log(errors)
    let property: keyof ApptReqFormErrors;
    for (property in errors) {
      if (errors[property] === true) {
        setErrorMessage(errors)
        return true;
      }
    }

    //Reset Error State
    const resetErr: ApptReqFormErrors = { ...errorMessage };
    for (property in resetErr) {
      resetErr[property] = false;
    }
    setErrorMessage(resetErr);

  };

  const resetForm = () => {
    setApptReqData({
      agencyName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phoneNumber: '',
      email: '',
      keyContactFirstName: '',
      keyContactLastName: '',
      keyContactTitle: '',
      agencyBusinessYrs: '',
      agencyStructure: '',
      agencySiteAddress: '',
      agencyNumOfficeLocations: '',
      officeLocations: '',
      commLineProd: '',
      commLineCSR: '',
      commLinePrem: '',
      commLineAcc: '',
      carr1: '',
      wp1: '',
      lr1: '',
      carr2: '',
      wp2: '',
      lr2: '',
      carr3: '',
      wp3: '',
      lr3: '',
      agencySpec: '',
      sizeOfAcc: '',
      addComments: ''
    });
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const err = validateForm();

    if (!err) {
      console.log("** Submitting");
      var formDetails = {
        'formData': JSON.stringify({ apptReqData }),
        //formName field defined here might not be used/needed anywhere:
        'formName': 'apptReq',
      };
      console.log('formDetals.formData: ' + formDetails.formData)

      try {
        axios.post('/api/process', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ formDetails })
        })
          .then((response: any) => response.data)
          .catch((err)=>{
            console.log('err: ' + err)
            setDisable(false);
            alert("An Error occurred during processing of form data.");
          })
          .then((responseData) => {
            // console.log('responseData: ' + responseData);
              if (responseData['status'] !== "Success") {
                // Failed to process the form data.
                setDisable(false);
                alert("An Error occurred during processing of form data.");
              } else {
                // Successful form processing. Show success text
                resetForm();
                setFormSuccess(true);
                setDisable(false);
                window.scrollTo(0, 0);
              }
          })
      } catch (err) {
        console.log('err: ' + err);
        setDisable(false);
        alert("An Error occurred during processing of form data.");
      }

    } else { //There were errors in validating form
      setDisable(false);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div style={{ display: formSuccess ? 'inline' : 'none' }}>
        <AARPostSub />
      </div>
      <div style={{ display: !formSuccess ? 'inline' : 'none' }}>
        <FieldGroup
          isFull
          labelVisual="Agency Appointment Request"
          labelTag="h1"
        >
          <p className="gray">Thank you for your interest in becoming a commercial lines agent with Liberty Mutual Insurance.</p>
          <p className="gray">Once you have entered the information requested below,
          a member of our field marketing staff will contact you within 30 days to review the information and further discuss your interest in becoming an agent with Liberty
          Mutual Insurance.
        </p>
          <p className="gray">Please visit <a href="https://www.safeco.com/agent-resources/apply">Safeco Insurance</a> to request a personal lines appointment.</p>

          {/* name, addr, city, state, zip, number, email, key contact first, last, title */}
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <FieldGroup>
                <AlphanumericInput data-field-name="company" labelVisual="* Agency Name" name="agencyName" value={apptReqData.agencyName} onChange={(e) => change(e)} />
                <span style={{ color: 'red', display: errorMessage.agencyName ? 'inline' : 'none' }}>
                  Agency Name required</span>
              </FieldGroup>
            </GridCol>
            <GridCol base={6}>
              <FieldGroup>
                <AlphanumericInput data-field-name="00N80000004c7OO" labelVisual="* Street Address" name="address" value={apptReqData.address} onChange={(e) => change(e)} />
                <span style={{ color: 'red', display: errorMessage.address ? 'inline' : 'none' }}>
                  Street Address required</span>
              </FieldGroup>
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput data-field-name="00N80000004c7OL" labelVisual="* City" name="city" value={apptReqData.city} onChange={(e) => change(e)} />
              <span style={{ color: 'red', display: errorMessage.city ? 'inline' : 'none' }}>
                City required</span>
            </GridCol>
            <GridCol base={2}>
              <StateSelect data-field-name="00N80000004c7ON" value={apptReqData.state} isApptReq={isApptReq} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.state ? 'inline' : 'none' }}>
                State required</span>
            </GridCol>
            <GridCol base={1} />
            <GridCol base={3}>
              <AlphanumericInput data-field-name="00N80000004c7OM" mask="zipCode" labelVisual="* Zip Code" name="zipCode" value={apptReqData.zipCode} onChange={(e) => change(e)} />
              <span style={{ color: 'red', display: errorMessage.zipCode ? 'inline' : 'none' }}>
                Valid Zip Code required</span>
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol>
              <AlphanumericInput data-field-name="00N80000005Sn2z" mask="phone" labelVisual="* Phone Number" name="phoneNumber" value={apptReqData.phoneNumber} onChange={(e) => change(e)} />
              <span style={{ color: 'red', display: errorMessage.phoneNumber ? 'inline' : 'none' }}>
                Valid Phone Number required</span>
            </GridCol>
            <GridCol>
              <AlphanumericInput data-field-name="email" labelVisual="* Email" name="email" value={apptReqData.email} onChange={(e) => change(e)} />
              <span style={{ color: 'red', display: errorMessage.email ? 'inline' : 'none' }}>
                Valid Email required</span>
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={3}>
              <AlphanumericInput data-field-name="first_name" filter={/^[A-Za-z- ]*$/} labelVisual="* Key Contact First Name" name="keyContactFirstName" value={apptReqData.keyContactFirstName} onChange={(e) => change(e)} />
              <span style={{ color: 'red', display: errorMessage.keyContactFirstName ? 'inline' : 'none' }}>
                Key Contact First Name required</span>
            </GridCol>
            <GridCol base={3}>
              <AlphanumericInput data-field-name="last_name" filter={/^[A-Za-z- ]*$/} labelVisual="* Key Contact Last Name" name="keyContactLastName" value={apptReqData.keyContactLastName} onChange={(e) => change(e)} />
              <span style={{ color: 'red', display: errorMessage.keyContactLastName ? 'inline' : 'none' }}>
                Key Contact Last Name required</span>
            </GridCol>
            <GridCol base={6}>
              <AlphanumericInput data-field-name="title" labelVisual="* Key Contact's Title" name="keyContactTitle" value={apptReqData.keyContactTitle} onChange={(e) => change(e)} />
              <span style={{
                color: 'red', display: errorMessage.keyContactTitle
                  ? 'inline' : 'none'
              }}>
                Key Contact's Title required</span>
            </GridCol>
          </GridRow>

          {/* agencyYrs, agencyStruct, officeLocation, numberOffice */}
          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={3}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N80000004W1os' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Agency's Years in Business"
                placeholderOption="Select One"
                name="agencyBusinessYrs"
                onChange={(e) => change(e)}
                value={apptReqData.agencyBusinessYrs}
              >
                {agencyFormData.agencyYears.map((yr) => <SelectOption key={yr} value={yr}>{yr}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.agencyBusinessYrs ? 'inline' : 'none' }}>
                Agency's Years in Business required</span>
            </GridCol>
            <GridCol base={3} />

            <GridCol base={3}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N80000004c7O9' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Agency's Structure"
                placeholderOption="Select One"
                name="agencyStructure"
                onChange={(e) => change(e)}
                value={apptReqData.agencyStructure}
              >
                {agencyFormData.agencyStructure.map((struct) => <SelectOption key={struct} value={struct}>{struct}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.agencyStructure ? 'inline' : 'none' }}>
                Agency's Structure required</span>
            </GridCol>
            <GridCol base={3} />
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput data-field-name="URL" name="agencySiteAddress" labelVisual="* Agency's Website Address" value={apptReqData.agencySiteAddress} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.agencySiteAddress ? 'inline' : 'none' }}>
                Agency's Website Address required</span>
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol>
              <AlphanumericInput data-field-name="00N1E000006Yf6X" name="agencyNumOfficeLocations" labelVisual="* Number of Agency Office Locations" value={apptReqData.agencyNumOfficeLocations} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.agencyNumOfficeLocations ? 'inline' : 'none' }}>
                Number of Agency Office Locations required</span>
            </GridCol>
            <GridCol>
              <AlphanumericInput data-field-name="00N1E000006Yf6Y" name="officeLocations" labelVisual="* Office Locations" value={apptReqData.officeLocations} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.officeLocations ? 'inline' : 'none' }}>
                Office Locations required</span>
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={4}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N1E000006Yf6V' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Number of Commerial Lines Producers"
                placeholderOption="Select One"
                name="commLineProd"
                onChange={(e) => change(e)}
                value={apptReqData.commLineProd}
              >
                {agencyFormData.commProd.map((i) => <SelectOption key={i} value={i}>{i}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.commLineProd ? 'inline' : 'none' }}>
                Number of Commerial Lines Producers required</span>
            </GridCol>
            <GridCol base={2} />

            <GridCol base={4}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N80000005SPSs' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Number of Commercial Lines CSRs"
                placeholderOption="Select One"
                name="commLineCSR"
                onChange={(e) => change(e)}
                value={apptReqData.commLineCSR}
              >
                {agencyFormData.commCSR.map((i) => <SelectOption key={i} value={i}>{i}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.commLineCSR ? 'inline' : 'none' }}>
                Number of Commercial Lines CSRs required</span>
            </GridCol>
            <GridCol base={2} />
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={4}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N1E000006Yf6U' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Total Commercial Lines Agency Premium"
                placeholderOption="Select One"
                name="commLinePrem"
                onChange={(e) => change(e)}
                value={apptReqData.commLinePrem}
              >
                {agencyFormData.agencyPrem.map((i) => <SelectOption key={i} value={i}>{i}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.commLinePrem ? 'inline' : 'none' }}>
                Total Commercial Lines Agency Premium required</span>
            </GridCol>
            <GridCol base={2} />
            <GridCol base={4}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N1E000006Yf6W' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Number of Commerial Lines Accounts"
                placeholderOption="Select One"
                name="commLineAcc"
                onChange={(e) => change(e)}
                value={apptReqData.commLineAcc}
              >
                {agencyFormData.commAcc.map((i) => <SelectOption key={i} value={i}>{i}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.commLineAcc ? 'inline' : 'none' }}>
                Number of Commerial Lines Accounts required</span>
            </GridCol>
            <GridCol base={2} />
          </GridRow>

          {/* Comm lines Markets */}
          <h5 className="gray">Top 3 Current Commercial Lines Markets (Carriers):</h5>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput data-field-name="00N80000004W1q5" name="carr1" labelVisual="Carrier" value={apptReqData.carr1} onChange={change} />
            </GridCol>
            <GridCol>
              <AlphanumericInput data-field-name="00N80000004W1qF" name="wp1" labelVisual="Written Premium" value={apptReqData.wp1} onChange={change} />
            </GridCol>
            <GridCol>
              <AlphanumericInput data-field-name="00N80000004W1qK" type="text" name="lr1" labelVisual="Loss Ratio %" filter={/^[0-9]*$/} value={apptReqData.lr1} onChange={change} />
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput data-field-name="00N80000004W1qU" name="carr2" labelVisual="Carrier" value={apptReqData.carr2} onChange={change} />
            </GridCol>
            <GridCol base={3}>
              <AlphanumericInput data-field-name="00N80000004W1qZ" name="wp2" labelVisual="Written Premium" value={apptReqData.wp2} onChange={change} />
            </GridCol>
            <GridCol base={3}>
              <AlphanumericInput data-field-name="00N80000004W1qe" type="text" name="lr2" labelVisual="Loss Ratio %" filter={/^[0-9]*$/} value={apptReqData.lr2} onChange={change} />
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput data-field-name="00N80000004W1qj" name="carr3" labelVisual="Carrier" value={apptReqData.carr3} onChange={change} />
            </GridCol>
            <GridCol base={3}>
              <AlphanumericInput data-field-name="00N80000004W1qo" name="wp3" labelVisual="Written Premium" value={apptReqData.wp3} onChange={change} />
            </GridCol>
            <GridCol base={3}>
              <AlphanumericInput data-field-name="00N80000004W1qt" type="text" name="lr3" labelVisual="Loss Ratio %" filter={/^[0-9]*$/} value={apptReqData.lr3} onChange={change} />
            </GridCol>
          </GridRow>


          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={6}>
              <AlphanumericInput data-field-name="00N80000005X5wx" name="agencySpec" labelVisual="* Agency specialization in a particular line of business or product" value={apptReqData.agencySpec} onChange={change} />
              <span style={{ color: 'red', display: errorMessage.agencySpec ? 'inline' : 'none' }}>
                Agency specialization in a particular line of business or product required</span>
            </GridCol>
            <GridCol base={4}>
              <Select
                id="select--default"
                isRequired
                inputProps={{ 'data-field-name': '00N1E000006Yf6Z' } as React.ComponentPropsWithoutRef<'select'>}
                labelA11y="Select One"
                labelVisual="* Size of accounts agency currently writes"
                placeholderOption="Select One"
                name="sizeOfAcc"
                onChange={(e) => change(e)}
                value={apptReqData.sizeOfAcc}
              >
                {agencyFormData.agencyWrites.map((i) => <SelectOption key={i} value={i}>{i}</SelectOption>)}
              </Select>
              <span style={{ color: 'red', display: errorMessage.sizeOfAcc ? 'inline' : 'none' }}>
                Size of accounts agency currently writes required</span>
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol>
              <AlphanumericInput data-field-name="description" name="addComments" labelVisual="Additional Comments" value={apptReqData.addComments} onChange={change} />
            </GridCol>
          </GridRow>

          <GridRow gutterSize="default" gutters="horizontal">
            <GridCol base={4} />
            * Required Fields
            <GridCol base={2}>
              <Button type="submit" dynamicWidth variant="primary" disabled={disable} onClick={() => setDisable(true)}>Submit</Button>
            </GridCol>
          <GridCol base={4} />
          </GridRow>
        </FieldGroup>
      </div>
    </Form>
  )
}

export default AgencyApptReqForm